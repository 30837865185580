"use strict";

import nq from "./nativeQuery.js";
import UI from "./UI.js";
import { Router } from "./Router";
import { Dispatcher } from "./Dispatcher";
import { OverlayManager } from "./Overlay.js";
import { Dialog } from "./Dialog";
import Navigation from './views/utils/navigation.js';
import Breadcrumb from './views/utils/breadcrumb.js'
import { isURL } from "validator";
import Notifications from "./controllers/notifications.js";


import serviceList from "../../public_html/serviceList.json";
import defaultConfig from "../../defaultConfig.json";

const themes = require('../../public_html/Themes/themes.json');
const ns_SVG2000 = "http://www.w3.org/2000/svg";


const App = function () {
  this.currentProfile = "default";
  this.serviceData = {};
  this.overlayManager = new OverlayManager();
  this.router = new Router(this.overlayManager);
  this.dispatcher = new Dispatcher();
  this.UI = new UI(this);


  // const accountBtn = this.UI.button();
  // accountBtn.container.addClass("button").addClass("accountBtn");
  this.containers = {
    main: nq("#mainContainer"),
    header: nq("header"),
    breadcrumb: new Breadcrumb(this),
    clear: function () { this.main.empty() }
  };

  this.initNavs = () => {
    const body = document.body
    const navMenu = nq.create("nav").appendTo(body);
    navMenu.attr("id", "navMenu").addClass("hidden").attr("role", "navigation").addClass("doNotUseThisClass");
    const topSide = nq.create("div").addClass("topSide")
    topSide.appendTo(navMenu);
    const duplicateMainNavButton = nq.createNS(ns_SVG2000, "svg").attr("viewBox", "0 0 600 150").attrNS(ns_SVG2000, "preserveAspectRatio", "none")
      .addClasses("textNavHeaderContainer headerElement textNavHeaderSVG")
      .appendTo(topSide)
    duplicateMainNavButton.innerHTML = `<polygon id="navHeaderUpper" points="0 0, 600 0, 600 150" fill="#000000"/>
             <image href="/img/logo.webp" x="325" y="-80" width="250" height="250" style="filter: brightness(140%)"></image>        
             <polygon id="navHeaderLower" points="0 150, 0 0, 600 150" fill="#3c3c3c"/>
             <image href="/img/account.svg" x="40" y="35" width="100" height="100"></image>`
    const upperNavbarContainer = nq.create("ul").addClass("upperNavBarContainer")
      .appendTo(topSide)
    upperNavbarContainer.innerHTML = `<li class="navElement"><a class="navElement" href="/">Home</a></li>
              <li class="navElement"><a class="navElement" href="/documentation">Documentation</a></li>
              <li class="navElement"><a class="navElement" href="/about">About</a></li>
              <li class="navElement"><a class="navElement" href="/contact">Contact</a></li>`

    const sideNavbarContainer = nq.create("ul").attr("id", "sideNavBar").attr("class", "sideNavBarContainer").appendTo(navMenu);

    const mainNavButton = document.getElementById("mainNavButton");
    const offlineHeader = document.getElementById("offlineHeader");
    const onlineHeader = document.getElementById("onlineHeader");

    mainNavButton.addEventListener("click", event => {
      event.stopPropagation();
      navMenu.classList.toggle("hidden") 
    })

    navMenu.addEventListener("click", event => {
      event.stopPropagation();
      navMenu.classList.toggle("hidden")
    })

    duplicateMainNavButton.addEventListener("click", event => {
      event.stopPropagation();
      navMenu.classList.toggle("hidden")
    })

    const navigation = new Navigation(this, sideNavbarContainer, "Account", "/account");

    navigation.add("info", "/img/account.svg", "Information", () => { this.router.push("/account/info"); this.router.goTo("/account/info"); });
    navigation.add("themes", "/img/themes.svg", "Themes", () => { this.router.push("/account/themes"); this.router.goTo("/account/themes"); });
    navigation.add("authentication", "/img/security.svg", "Password & authentication", () => { this.router.push("/account/authentication"); this.router.goTo("/account/authentication"); });
    navigation.add("advanced", "/img/advanced.svg", "Advanced", () => { this.router.push("/account/advanced"); this.router.goTo("/account/advanced"); });
    navigation.addSeparator();
    navigation.addButton("/img/logout.svg", "Logout", () => { 
      this.deleteUserData();
      window.location.pathname = "/";
    });
  }

  this.config = defaultConfig;
  this.modifierKeys = {};
  this.userData = {locale: "en-US", timeZone: "Europe/Paris"};



  this.config.set = (group, variable, value) => {
    if (this.config[group] === undefined)
      return false;
    this.config[group][variable] = value;

    const data = {};
    data[group + "·" + variable] = value;

    this.fetchAPI("gui-core", "/profiles/" + this.currentProfile + "/config", "PUT", { data: JSON.stringify(data) })
      .then(() => {})
      .catch(err => {
        this.log("error saving config:", err);
      });


  };

  this.loadServiceData = (callback) => {
    const promises = [];
    const discribeRoute = "/describeAPI";
    for (const service of serviceList.internal)
      promises.push(fetch(service + discribeRoute));
    for (const service of serviceList.components)
      promises.push(fetch(service + discribeRoute));

    Promise.allSettled(promises).then(async (result) => {
      for (const res of result) {
        if (res.status === "fulfilled") {
          const data = await res.value.json();
          data.url = res.value.url.substring(
            0,
            res.value.url.length - discribeRoute.length
          );
          this.serviceData[data.info["x-service-id"]] = data;
        }
      }
      callback();
    });
  };

  this.internalService = (serviceName) => {
    if (serviceName === "projects")
      return this.serviceData["b40bd0d4-0ce4-4df7-bccc-401875c818f7"];
    if (serviceName === "gui-core")
      return this.serviceData["9f4a17d6-4b60-4599-ad0d-7eae25f7b885"];
    if (serviceName === "workflows")
      return this.serviceData["dac68ee1-b2c4-4284-9bb0-49e6cdb11955"];
    if (serviceName === "accounts")
      return this.serviceData["321cb143-d88e-4a57-9bf4-48833c841863"];
  };

  this.dialog = () => {
    const overlay = this.overlayManager.create();

    overlay.setClass("_dialog");
    return new Dialog(this, overlay);
  };

  this.loadSVG = (url) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) =>
          response
            .text()
            .then((svg) => {
              const div = nq.create("div");
              div.innerHTML = svg.trim();
              resolve(div.find("svg"));
            })
            .catch(reject)
        )
        .catch(reject);
    });
  };

  // TODO to be rewrite/refactor when upgrading UI
  this.fetchAPIRaw = (serviceIdOrUrl, route, method, body, token, frontCompId) => {
    return new Promise((resolve, reject) => {
      let url = this.internalService(serviceIdOrUrl)?.url;
      if (!url) {
        if (!isURL(serviceIdOrUrl, { require_tld: false, require_host: false })) {
          return reject("Unknown service '" + serviceIdOrUrl + "'");
        }
        url = serviceIdOrUrl;
      }
      let queryString;

      

      const headers = {
          "Accept": "application/json; charset=utf-8",
          "Content-Type": "text/plain; charset=utf-8",
          "Authorization": token,
        }
      if(frontCompId !== undefined)
        headers["x-actorId"] = frontCompId;
      
      fetch(url + route + (method === "GET" ? "?" + queryString : ""), {
        method,
        headers,
        body: method === "GET" ? undefined : body,
      })
        .then((result) => {
          result
            .json()
            .then((json) => {
              result.data = json;
              resolve(result);
            })
            .catch(() => {
              resolve(result);
            });
        })
        .catch((err) => {
          this.log("Error while requesting service:", serviceIdOrUrl, err);
          reject();
        });
    });
  };
  
  this.fetchAPI = (serviceIdOrUrl, route, method, body, token, frontCompId) => {
    return new Promise((resolve, reject) => {
      let url = this.internalService(serviceIdOrUrl)?.url;
      if (!url) {
        if (!isURL(serviceIdOrUrl, { require_tld: false, require_host: false })) {
          return reject("Unknown service '" + serviceIdOrUrl + "'");
        }
        url = serviceIdOrUrl;
      }
      let queryString;

      if (method === "GET") {
        if (body === undefined)
          body = {};

        const params = new URLSearchParams();

        for (const [key, value] of Object.entries(body))
          if (Array.isArray(value) || typeof value === "object")
            params.append(key, JSON.stringify(value));
          else
            params.append(key, value) 

        queryString = params.toString();
      }

      const headers = {
          "Accept": "application/json; charset=utf-8",
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": token,
        }
      if(frontCompId !== undefined)
        headers["x-actorId"] = frontCompId;
      
      fetch(url + route + (method === "GET" ? "?" + queryString : ""), {
        method,
        headers,
        body: method === "GET" ? undefined : JSON.stringify(body),
      })
        .then((result) => {
          result
            .json()
            .then((json) => {
              result.data = json;
              resolve(result);
            })
            .catch(() => {
              resolve(result);
            });
        })
        .catch((err) => {
          this.log("Error while requesting service:", serviceIdOrUrl, err);
          reject();
        });
    });
  };

  this.setUserData = (accessToken, refreshToken) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    this.userData.accessToken = accessToken;
    this.userData.refreshToken = refreshToken;
    this.userData.id = accessToken; // TO DO replace with a proper ID (must be extracted from the accessToken)
  };

  this.deleteUserData = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    this.userData = {};
  }

  this._restoreUserData = () => {
    return new Promise((resolve, reject) => {
      this.userData.accessToken = localStorage.getItem("accessToken") || undefined;
      this.userData.refreshToken = localStorage.getItem("refreshToken") || undefined;
      this.userData.id = this.userData.accessToken; // TO DO replace with a proper ID (must be extracted from the accessToken)

      this.fetchAPI("gui-core", "/profiles/" + this.currentProfile + "/config")
        .then(result => {
          if (result.status !== 200) {
            this.log("Error getting config", result.data);
            return resolve();
          }

          for (let key in result.data) {
            const k = key.split("·");
            if (this.config[k[0]] === undefined)
              this.config[k[0]] = [];
            this.config[k[0]][k[1]] = result.data[key];
          }
          resolve();
        })
        .catch(err => {
          this.log("Error getting config", err);
          resolve();
        });
    });
  }

  this.log = (...params) => {
    console.log(...params);
  };

  this._initUI = () => {
    // this.containers.header.append(accountBtn.container);
    // this.updateAccountButton();
    this.initNavs();
    this.updateNavHeader();

    if (this.userData.refreshToken !== undefined)
      this.setTheme(this.config.UI?.theme, true);

  };

  this.updateAccountButton = () => {
    if (this.userData.refreshToken === undefined) {
      // accountBtn.container.innerText = "Connect";
      // accountBtn.setActionCallback(() => {
      //   window.location.pathname = "/";
      // });
    }
    else {
      // accountBtn.container.innerText = "My account";
      // accountBtn.setActionCallback(() => {
      //   this.router.push("/account");
      //   this.router.goTo("/account");
      // });
    }
  }

  this.updateNavHeader = () => {
    if (this.userData.refreshToken === undefined) {
      offlineHeader.classList.remove("hidden");
      onlineHeader.classList.add("hidden");
    }
    else {
      offlineHeader.classList.add("hidden");
      onlineHeader.classList.remove("hidden");
    }
  }

  this.setTheme = (theme, isRestoration) => {
    if (theme === undefined || theme === "" || themes.indexOf(theme) === -1)
      return;

    fetch('/dist/manifest.json')
      .then(result => {
        result.json().then(buildMap => {
          this._setThemeCookie(theme);
          const cssFile = buildMap["css/themes/" + theme + ".css"];

          if (cssFile === undefined)
            return;

          const data = JSON.stringify({ "UI·theme": theme });
          if (!isRestoration)
            this.fetchAPI("gui-core", "/profiles/" + this.currentProfile + "/config", "PUT", { data });

          const link = nq("#themeStylesheet");
          let href = link.href;
          let protocolEndIndex = href.indexOf("://");
          if (protocolEndIndex === -1)
            protocolEndIndex = 0;
           
          href = href.substring(0, href.indexOf("/", protocolEndIndex + 3)) + cssFile;
          link.href = href;
        })
          .catch(err => {
            this.log("Error parsing manifest.json file", err);
          })
      })
      .catch(err => {
        this.log("Error getting manifest.json file", err);
      });

  }

  this._setThemeCookie = theme => {
    let date = new Date();
    date.setTime(date.getTime() + (15_552_000_000)); // 180 days
    const expires = "expires=" + date.toUTCString();
    document.cookie = "UI_theme=" + theme + "; " + expires + "; path=/; sameSite=lax;	";
  }


  this.updateUI = () => {
    this.updateAccountButton();
  };
  
  this.unloggedRedirect = () => {
    if (this.userData.refreshToken === undefined) {
      this.router.replace("/");
      this.router.goTo("/");
      return true;
    }
		
    return false;
  }

  this.ifLoggedRedirectTo = page => {
    if (this.userData.refreshToken !== undefined) {
      this.router.replace(page);
      this.router.goTo(page)
      return true;
    }
    return false;
  }

  this.init = () => {
    return new Promise((resolve, reject) => {
      this.loadServiceData(async () => {
        await this._restoreUserData();
        this._initUI();
        this.router.setUIRefreshCallback(this.updateUI);
        this.notifications = new Notifications(this);
        resolve();
      });
    });
  }
};

export { App };
